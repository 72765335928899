<template>
  <div>
    <div class="div1">{{ data.name }}</div>
    <div class="div2">{{ data.title }}</div>

    <div class="con">
      <div v-for="(item, index) in data.list" :key="item.id">
        <div class="one" v-html="delHtmlTag(item.content)">
          {{ item.content }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getScience } from "../../api/keyan";
export default {
  data() {
    return {
      data: [],
    };
  },
  methods: {
    // delHtmlTag(str) {
    //   if (str != null) {
    //     return str.replace(/<[^>]+>/g, "");
    //   }
    // },
    delHtmlTag(str) {
      // console.log(str)
      // if (str != null || str == undefined  ) {
      //   console.log(str.replace(/<[^>]+>/g, ""))
      //   return str.replace(/<[^>]+>/g, "");
      if (str != null || str == undefined  ) {
        let estr = str
            // .replace(/<[^>]+>/g, "")
                .replace(/&lt;/g,"<")
                .replace(/&gt;/g,">")
                .replace(/&amp;/g, "")
                // .replace(/&nbsp;/g,"")
                // .replace(/nbsp;/g,"")
                .replace(/&quot;/g, '"')
        ;
        // console.log(estr)
        return estr

      }else {
        // console.log(222);
      }

    },
  },
  mounted() {
    getScience().then((res) => {
      console.log(res.data[2]);
      let data = res.data[2];
      // console.log(data);
      this.data = data;
    });
  },
};
</script>

<style lang="less" scoped>
  /deep/img{
    display: block;
    width: 60vw;
    margin: 0 auto;

  }
  /deep/span{
    font-size: 18px!important;
    font-family: 华文仿宋!important;
    line-height: 1.2;
  }
  /deep/p{
    font-size: 18px;
    font-family: 华文仿宋!important;
    line-height: 1.2;
  }
  /deep/strong{
    font-size: 22px;
    font-family: 华文仿宋!important;
    line-height: 1.2;
    font-weight: 600;
  }
  .div1 {
    height: 2.0833vw;
    font-size: 2.2917vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #d7000f;
    margin-top: 4.1146vw;
    text-align: center;
    letter-spacing:0.15vw;
  }
  .div2 {
    height: 1.0417vw;
    font-size: 1.3542vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #646464;
    margin-top: 1.0417vw;
    text-align: center;
  }
  .con {
    margin-top: 5vw;
    margin-bottom: 10.4167vw;
  }
  .one {
    text-indent: 2em;
    width: 76%;
    font-size: 1vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #000000;
    margin-left: 13%;
    line-height: 2vw;
    margin-top: 3.1042vw;
  }
  .two {
    margin-left: 13%;
    img {
      width: 24.7083vw;
      height: 14.75vw;
      margin-right: 0.625vw;
      margin-top: 1.2458vw;
    }
  }
</style>
